// React Basic and Bootstrap
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { TabContent, TabPane, Nav, NavItem, NavLink, Row, Col } from 'reactstrap';
import classnames from 'classnames';
import {MDBBtn, MDBIcon, MDBBadge} from 'mdbreact'

class Schedule extends Component {

    constructor(props) {
        super(props);
        const currentMonth = new Date().getMonth()+1

        this.state = {
            activeTab: `${currentMonth}`
        }
        this.toggle = this.toggle.bind(this);
    }

    toggle(tab) {
        this.setState({ activeTab: tab });
    }



    render() {
        return (
            <React.Fragment>
                <section className="section">
                    <div className="container">
                        <Row className="justify-content-center">
                            <Col className="text-center">
                                <div className="section-title mb-4 pb-2">
                                    <h4 className="title mb-4">Courses Schedule</h4>
                                    <p className="text-muted para-desc mx-auto mb-0">Our courses are conducted in <span className="text-primary font-weight-bold">small batches</span> so that the instructor can maximize the learning experience for each participant.</p>
                                </div>
                            </Col>
                        </Row>

                        <Row className="justify-content-center">
                            <Col lg={8} md={12} className="text-center" style={{ marginTop : "10px" }}>

                                <Nav pills>
                                    <NavItem>
                                        <NavLink
                                            className={classnames({ active: this.state.activeTab === '9' })}
                                            onClick={() => { this.toggle('9'); }} >
                                            <div className="text-center pt-1 pb-1" style={{ minWidth : "100px" }}>
                                                <h4 className="title font-weight-normal mb-0">SEPTEMBER</h4>
                                            </div>
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            className={classnames({ active: this.state.activeTab === '10' })}
                                            onClick={() => { this.toggle('10'); }} >
                                            <div className="text-center pt-1 pb-1" style={{ minWidth : "100px" }}>
                                                <h4 className="title font-weight-normal mb-0">OCTOBER</h4>
                                            </div>
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            className={classnames({ active: this.state.activeTab === '11' })}
                                            onClick={() => { this.toggle('11'); }} >
                                            <div className="text-center pt-1 pb-1" style={{ minWidth : "100px" }}>
                                                <h4 className="title font-weight-normal mb-0">NOVEMBER</h4>
                                            </div>
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            className={classnames({ active: this.state.activeTab === '12' })}
                                            onClick={() => { this.toggle('12'); }} >
                                            <div className="text-center pt-1 pb-1" style={{ minWidth : "100px" }}>
                                                <h4 className="title font-weight-normal mb-0">DECEMBER</h4>
                                            </div>
                                        </NavLink>
                                    </NavItem>
                                </Nav>
                            </Col>
                        </Row>

                        <Row>
                            <Col>
                                <div className="tab-content pt-1" id="pills-tabContent">
                                    <TabContent activeTab={this.state.activeTab}>
                                          
                                         <TabPane tabId="9">
                                            <Row>
                                                 <Col lg={6} className="mt-4 pt-2">
                                                     <div className="event-schedule d-flex bg-white rounded p-3 border">
                                                         <div className="float-left">
                                                             <ul className="date text-center text-primary mr-md-4 mr-3 mb-0 list-unstyled">
                                                                 <li className="day font-weight-bold mb-2" style={{ fontSize : "12px" }}>4</li>
                                                                 <li className="month font-weight-bold">DAYS</li>
                                                                 <li className="month font-weight-bold"><MDBBadge tag="a" color="green">physical</MDBBadge></li>
                                                             </ul>
                                                         </div>
                                                         <div className="content">
                                                             <h4><Link to="/trainings/pmp-certification-training-philippines" className="text-dark title">Project Management Professional PMP® Training</Link></h4>
                                                             <p className="text-muted location-time"> 
                                                                 <span className="text-dark h6">Address:</span> Hotel in city center <br /> 
                                                                 <span className="text-dark h6">Time:</span> 09:00AM to 17:45PM <br />
                                                                 <span className="text-dark h6">Mon-Thu</span> <br />
                                                                 <span className="text-dark h6">September 26-19</span></p>
                                                             <MDBBtn color="info" onClick={()=>this.props.toggle2('enrolform')}>Enrol <MDBIcon icon={'user'} className="ml-1"/></MDBBtn>
                                                         </div>
                                                     </div>
                                                 </Col>

                                                <Col lg={6} className="mt-4 pt-2">
                                                     <div className="event-schedule d-flex bg-white rounded p-3 border">
                                                         <div className="float-left">
                                                             <ul className="date text-center text-primary mr-md-4 mr-3 mb-0 list-unstyled">
                                                                 <li className="day font-weight-bold mb-2" style={{ fontSize : "12px" }}>2</li>
                                                                 <li className="month font-weight-bold">DAYS</li>
                                                                 <li className="month font-weight-bold"><MDBBadge tag="a" color="blue">virtual</MDBBadge></li>
                                                             </ul>
                                                         </div>
                                                         <div className="content">
                                                             <h4><Link to="/data/science/training/philippines" className="text-dark title">Big Data/Data Science/ML Foundation</Link></h4>
                                                             <p className="text-muted location-time">
                                                                 <span className="text-dark h6">Address:</span> Virtual live classroom <br /> 
                                                                 <span className="text-dark h6">Time:</span> 09:00AM to 17:45PM <br />
                                                                 <span className="text-dark h6">Thu-Fri</span> <br />
                                                                 <span className="text-dark h6">September 29-30</span></p>
                                                             <MDBBtn color="info" onClick={()=>this.props.toggle2('enrolform')}>Enrol <MDBIcon icon={'user'} className="ml-1"/></MDBBtn>
                                                         </div>
                                                     </div>
                                                 </Col>
                                                 <Col lg={6} className="mt-4 pt-2">
                                                     <div className="event-schedule d-flex bg-white rounded p-3 border">
                                                         <div className="float-left">
                                                             <ul className="date text-center text-primary mr-md-4 mr-3 mb-0 list-unstyled">
                                                                 <li className="day font-weight-bold mb-2" style={{ fontSize : "12px" }}>2</li>
                                                                 <li className="month font-weight-bold">DAYS</li>
                                                                 <li className="month font-weight-bold"><MDBBadge tag="a" color="blue">virtual</MDBBadge></li>
                                                             </ul>
                                                         </div>
                                                         <div className="content">
                                                             <h4><Link to="/trainings/pmi_acp-philippines" className="text-dark title">PMI ACP Training</Link></h4>
                                                             <p className="text-muted location-time">
                                                                 <span className="text-dark h6">Address:</span> Virtual live classroom <br /> 
                                                                 <span className="text-dark h6">Time:</span> 09:00AM to 17:45PM <br />
                                                                 <span className="text-dark h6">Thu-Fri</span> <br />
                                                                 <span className="text-dark h6">September 15-16</span></p>
                                                             <MDBBtn color="info" onClick={()=>this.props.toggle2('enrolform')}>Enrol <MDBIcon icon={'user'} className="ml-1"/></MDBBtn>
                                                         </div>
                                                     </div>
                                                 </Col>
                                            </Row>
                                        </TabPane>

                                        <TabPane tabId="10">
                                            <Row>
                                                <Col lg={6} className="mt-4 pt-2">
                                                     <div className="event-schedule d-flex bg-white rounded p-3 border">
                                                         <div className="float-left">
                                                             <ul className="date text-center text-primary mr-md-4 mr-3 mb-0 list-unstyled">
                                                                 <li className="day font-weight-bold mb-2" style={{ fontSize : "12px" }}>4</li>
                                                                 <li className="month font-weight-bold">DAYS</li>
                                                                 <li className="month font-weight-bold"><MDBBadge tag="a" color="blue">virtual</MDBBadge></li>
                                                             </ul>
                                                         </div>
                                                         <div className="content">
                                                             <h4><Link to="/trainings/pmp-certification-training-philippines" className="text-dark title">Project Management Professional PMP® Training</Link></h4>
                                                             <p className="text-muted location-time"> 
                                                                 <span className="text-dark h6">Address:</span> Virtual live classroom <br /> 
                                                                 <span className="text-dark h6">Time:</span> 09:00AM to 17:45PM <br />
                                                                 <span className="text-dark h6">Sat-Sun, Sat-Sun</span> <br />
                                                                 <span className="text-dark h6">October 1-2, 8-9</span></p>
                                                             <MDBBtn color="info" onClick={()=>this.props.toggle2('enrolform')}>Enrol <MDBIcon icon={'user'} className="ml-1"/></MDBBtn>
                                                         </div>
                                                     </div>
                                                 </Col>
                                                 <Col lg={6} className="mt-4 pt-2">
                                                     <div className="event-schedule d-flex bg-white rounded p-3 border">
                                                         <div className="float-left">
                                                             <ul className="date text-center text-primary mr-md-4 mr-3 mb-0 list-unstyled">
                                                                 <li className="day font-weight-bold mb-2" style={{ fontSize : "12px" }}>4</li>
                                                                 <li className="month font-weight-bold">DAYS</li>
                                                                 <li className="month font-weight-bold"><MDBBadge tag="a" color="green">physical</MDBBadge></li>
                                                             </ul>
                                                         </div>
                                                         <div className="content">
                                                             <h4><Link to="/trainings/pmp-certification-training-philippines" className="text-dark title">Project Management Professional PMP® Training</Link></h4>
                                                             <p className="text-muted location-time"> 
                                                                 <span className="text-dark h6">Address:</span> Hotel in city center <br /> 
                                                                 <span className="text-dark h6">Time:</span> 09:00AM to 17:45PM <br />
                                                                 <span className="text-dark h6">Mon-Thu</span> <br />
                                                                 <span className="text-dark h6">October 24-27</span></p>
                                                             <MDBBtn color="info" onClick={()=>this.props.toggle2('enrolform')}>Enrol <MDBIcon icon={'user'} className="ml-1"/></MDBBtn>
                                                         </div>
                                                     </div>
                                                 </Col>

                                                <Col lg={6} className="mt-4 pt-2">
                                                     <div className="event-schedule d-flex bg-white rounded p-3 border">
                                                         <div className="float-left">
                                                             <ul className="date text-center text-primary mr-md-4 mr-3 mb-0 list-unstyled">
                                                                 <li className="day font-weight-bold mb-2" style={{ fontSize : "12px" }}>2</li>
                                                                 <li className="month font-weight-bold">DAYS</li>
                                                                 <li className="month font-weight-bold"><MDBBadge tag="a" color="blue">virtual</MDBBadge></li>
                                                             </ul>
                                                         </div>
                                                         <div className="content">
                                                             <h4><Link to="/data/science/training/philippines" className="text-dark title">Big Data/Data Science/ML Foundation</Link></h4>
                                                             <p className="text-muted location-time">
                                                                 <span className="text-dark h6">Address:</span> Virtual live classroom <br /> 
                                                                 <span className="text-dark h6">Time:</span> 09:00AM to 17:45PM <br />
                                                                 <span className="text-dark h6">Thu-Fri</span> <br />
                                                                 <span className="text-dark h6">October 27-28</span></p>
                                                             <MDBBtn color="info" onClick={()=>this.props.toggle2('enrolform')}>Enrol <MDBIcon icon={'user'} className="ml-1"/></MDBBtn>
                                                         </div>
                                                     </div>
                                                 </Col>
                                                 <Col lg={6} className="mt-4 pt-2">
                                                     <div className="event-schedule d-flex bg-white rounded p-3 border">
                                                         <div className="float-left">
                                                             <ul className="date text-center text-primary mr-md-4 mr-3 mb-0 list-unstyled">
                                                                 <li className="day font-weight-bold mb-2" style={{ fontSize : "12px" }}>2</li>
                                                                 <li className="month font-weight-bold">DAYS</li>
                                                                 <li className="month font-weight-bold"><MDBBadge tag="a" color="blue">virtual</MDBBadge></li>
                                                             </ul>
                                                         </div>
                                                         <div className="content">
                                                             <h4><Link to="/trainings/pmi_acp-philippines" className="text-dark title">PMI ACP Training</Link></h4>
                                                             <p className="text-muted location-time">
                                                                 <span className="text-dark h6">Address:</span> Virtual live classroom <br /> 
                                                                 <span className="text-dark h6">Time:</span> 09:00AM to 17:45PM <br />
                                                                 <span className="text-dark h6">Thu-Fri</span> <br />
                                                                 <span className="text-dark h6">October 6-7</span></p>
                                                             <MDBBtn color="info" onClick={()=>this.props.toggle2('enrolform')}>Enrol <MDBIcon icon={'user'} className="ml-1"/></MDBBtn>
                                                         </div>
                                                     </div>
                                                 </Col>
                                            </Row>
                                        </TabPane>

                                        <TabPane tabId="11">
                                            <Row>
                                                 <Col lg={6} className="mt-4 pt-2">
                                                     <div className="event-schedule d-flex bg-white rounded p-3 border">
                                                         <div className="float-left">
                                                             <ul className="date text-center text-primary mr-md-4 mr-3 mb-0 list-unstyled">
                                                                 <li className="day font-weight-bold mb-2" style={{ fontSize : "12px" }}>4</li>
                                                                 <li className="month font-weight-bold">DAYS</li>
                                                                 <li className="month font-weight-bold"><MDBBadge tag="a" color="green">physical</MDBBadge></li>
                                                             </ul>
                                                         </div>
                                                         <div className="content">
                                                             <h4><Link to="/trainings/pmp-certification-training-philippines" className="text-dark title">Project Management Professional PMP® Training</Link></h4>
                                                             <p className="text-muted location-time"> 
                                                                 <span className="text-dark h6">Address:</span> Hotel in city center <br /> 
                                                                 <span className="text-dark h6">Time:</span> 09:00AM to 17:45PM <br />
                                                                 <span className="text-dark h6">Mon-Thu</span> <br />
                                                                 <span className="text-dark h6">November 21-24</span></p>
                                                             <MDBBtn color="info" onClick={()=>this.props.toggle2('enrolform')}>Enrol <MDBIcon icon={'user'} className="ml-1"/></MDBBtn>
                                                         </div>
                                                     </div>
                                                 </Col>

                                                <Col lg={6} className="mt-4 pt-2">
                                                     <div className="event-schedule d-flex bg-white rounded p-3 border">
                                                         <div className="float-left">
                                                             <ul className="date text-center text-primary mr-md-4 mr-3 mb-0 list-unstyled">
                                                                 <li className="day font-weight-bold mb-2" style={{ fontSize : "12px" }}>2</li>
                                                                 <li className="month font-weight-bold">DAYS</li>
                                                                 <li className="month font-weight-bold"><MDBBadge tag="a" color="green">physical</MDBBadge></li>
                                                             </ul>
                                                         </div>
                                                         <div className="content">
                                                             <h4><Link to="/data/science/training/philippines" className="text-dark title">Big Data/Data Science/ML Foundation</Link></h4>
                                                             <p className="text-muted location-time">
                                                                 <span className="text-dark h6">Address:</span> Hotel in city center <br /> 
                                                                 <span className="text-dark h6">Time:</span> 09:00AM to 17:45PM <br />
                                                                 <span className="text-dark h6">Thu-Fri</span> <br />
                                                                 <span className="text-dark h6">November 24-25</span></p>
                                                             <MDBBtn color="info" onClick={()=>this.props.toggle2('enrolform')}>Enrol <MDBIcon icon={'user'} className="ml-1"/></MDBBtn>
                                                         </div>
                                                     </div>
                                                 </Col>
                                                 <Col lg={6} className="mt-4 pt-2">
                                                     <div className="event-schedule d-flex bg-white rounded p-3 border">
                                                         <div className="float-left">
                                                             <ul className="date text-center text-primary mr-md-4 mr-3 mb-0 list-unstyled">
                                                                 <li className="day font-weight-bold mb-2" style={{ fontSize : "12px" }}>2</li>
                                                                 <li className="month font-weight-bold">DAYS</li>
                                                                 <li className="month font-weight-bold"><MDBBadge tag="a" color="blue">virtual</MDBBadge></li>
                                                             </ul>
                                                         </div>
                                                         <div className="content">
                                                             <h4><Link to="/trainings/pmi_acp-philippines" className="text-dark title">PMI ACP Training</Link></h4>
                                                             <p className="text-muted location-time">
                                                                 <span className="text-dark h6">Address:</span> Virtual live classroom <br /> 
                                                                 <span className="text-dark h6">Time:</span> 09:00AM to 17:45PM <br />
                                                                 <span className="text-dark h6">Thu-Fri</span> <br />
                                                                 <span className="text-dark h6">November 10-11</span></p>
                                                             <MDBBtn color="info" onClick={()=>this.props.toggle2('enrolform')}>Enrol <MDBIcon icon={'user'} className="ml-1"/></MDBBtn>
                                                         </div>
                                                     </div>
                                                 </Col>
                                            </Row>
                                        </TabPane>

                                        <TabPane tabId="12">
                                            <Row>
                                                 <Col lg={6} className="mt-4 pt-2">
                                                     <div className="event-schedule d-flex bg-white rounded p-3 border">
                                                         <div className="float-left">
                                                             <ul className="date text-center text-primary mr-md-4 mr-3 mb-0 list-unstyled">
                                                                 <li className="day font-weight-bold mb-2" style={{ fontSize : "12px" }}>4</li>
                                                                 <li className="month font-weight-bold">DAYS</li>
                                                                 <li className="month font-weight-bold"><MDBBadge tag="a" color="green">physical</MDBBadge></li>
                                                             </ul>
                                                         </div>
                                                         <div className="content">
                                                             <h4><Link to="/trainings/pmp-certification-training-philippines" className="text-dark title">Project Management Professional PMP® Training</Link></h4>
                                                             <p className="text-muted location-time"> 
                                                                 <span className="text-dark h6">Address:</span> Hotel in city center <br /> 
                                                                 <span className="text-dark h6">Time:</span> 09:00AM to 17:45PM <br />
                                                                 <span className="text-dark h6">Mon-Thu</span> <br />
                                                                 <span className="text-dark h6">December 19-22</span></p>
                                                             <MDBBtn color="info" onClick={()=>this.props.toggle2('enrolform')}>Enrol <MDBIcon icon={'user'} className="ml-1"/></MDBBtn>
                                                         </div>
                                                     </div>
                                                 </Col>

                                                <Col lg={6} className="mt-4 pt-2">
                                                     <div className="event-schedule d-flex bg-white rounded p-3 border">
                                                         <div className="float-left">
                                                             <ul className="date text-center text-primary mr-md-4 mr-3 mb-0 list-unstyled">
                                                                 <li className="day font-weight-bold mb-2" style={{ fontSize : "12px" }}>2</li>
                                                                 <li className="month font-weight-bold">DAYS</li>
                                                                 <li className="month font-weight-bold"><MDBBadge tag="a" color="green">physical</MDBBadge></li>
                                                             </ul>
                                                         </div>
                                                         <div className="content">
                                                             <h4><Link to="/data/science/training/philippines" className="text-dark title">Big Data/Data Science/ML Foundation</Link></h4>
                                                             <p className="text-muted location-time">
                                                                 <span className="text-dark h6">Address:</span> Hotel in city center <br /> 
                                                                 <span className="text-dark h6">Time:</span> 09:00AM to 17:45PM <br />
                                                                 <span className="text-dark h6">Thu-Fri</span> <br />
                                                                 <span className="text-dark h6">December 29-30</span></p>
                                                             <MDBBtn color="info" onClick={()=>this.props.toggle2('enrolform')}>Enrol <MDBIcon icon={'user'} className="ml-1"/></MDBBtn>
                                                         </div>
                                                     </div>
                                                 </Col>
                                                 <Col lg={6} className="mt-4 pt-2">
                                                     <div className="event-schedule d-flex bg-white rounded p-3 border">
                                                         <div className="float-left">
                                                             <ul className="date text-center text-primary mr-md-4 mr-3 mb-0 list-unstyled">
                                                                 <li className="day font-weight-bold mb-2" style={{ fontSize : "12px" }}>2</li>
                                                                 <li className="month font-weight-bold">DAYS</li>
                                                                 <li className="month font-weight-bold"><MDBBadge tag="a" color="blue">virtual</MDBBadge></li>
                                                             </ul>
                                                         </div>
                                                         <div className="content">
                                                             <h4><Link to="/trainings/pmi_acp-philippines" className="text-dark title">PMI ACP Training</Link></h4>
                                                             <p className="text-muted location-time">
                                                                 <span className="text-dark h6">Address:</span> Virtual live classroom <br /> 
                                                                 <span className="text-dark h6">Time:</span> 09:00AM to 17:45PM <br />
                                                                 <span className="text-dark h6">Thu-Fri</span> <br />
                                                                 <span className="text-dark h6">December 1-2</span></p>
                                                             <MDBBtn color="info" onClick={()=>this.props.toggle2('enrolform')}>Enrol <MDBIcon icon={'user'} className="ml-1"/></MDBBtn>
                                                         </div>
                                                     </div>
                                                 </Col>
                                            </Row>
                                        </TabPane>
                                         
                                    </TabContent>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </section>
            </React.Fragment>
        );
    }
}

export default Schedule;