import React from "react";
import { Route, Switch } from "react-router-dom";
import { MDBContainer, MDBIcon, MDBBtn, MDBRow } from "mdbreact";

// import Home from "./containers/Home"
const Home = React.lazy(()=>import("./containers/Home"))

// const Neighborhood = React.lazy(()=>import("./containers/Neighborhood"))
// // import NotFound from "./containers/NotFound"
const NotFound = React.lazy(()=>import("./containers/NotFound"))
// // import Login from "./containers/Login"
// const Login = React.lazy(()=>import("./containers/Login"))
// // import Signup from "./containers/Signup"
// const Signup = React.lazy(()=>import("./containers/Signup"))
// // import AppliedRoute from "./components/AppliedRoute";
// const AppliedRoute = React.lazy(()=>import("./components/AppliedRoute"))
// // import AuthenticatedRoute from "./components/AuthenticatedRoute";
// const AuthenticatedRoute = React.lazy(()=>import("./components/AuthenticatedRoute"))
// // import ResetPassword from "./containers/ResetPassword"
// const ResetPassword = React.lazy(()=>import("./containers/ResetPassword"))
// import UnauthenticatedRoute from "./components/UnauthenticatedRoute"
const UnauthenticatedRoute = React.lazy(()=>import("./components/UnauthenticatedRoute"))
// // import NewJob from "./containers/NewJob"
// const NewJob = React.lazy(()=>import("./containers/NewJob"))
// // import Jobs from "./containers/Jobs"
// const Jobs = React.lazy(()=>import("./containers/Jobs"))
// // import Profile from "./containers/Profile"
// const Profile = React.lazy(()=>import("./containers/Profile"))

// const ProfileController = React.lazy(()=>import('./Profile/ProfileController'))
// const ProfileDisplay = React.lazy(()=>import('./Profile/ProfileDisplay'))

// const CourseController = React.lazy(()=>import('./Courses/CourseController'))
// const CourseControllerPublic = React.lazy(()=>import('./Courses/CourseControllerPublic'))


// const SearchApp = React.lazy(()=>import('./Search/SearchApp'))
// const SearchAppPublic = React.lazy(()=>import('./Search/SearchAppPublic'))

const BigDataDataScienceMLFoundationCourse = React.lazy(()=>import('./containers/BigDataDataScienceMLFoundationCourse')) 
const PMPCourse = React.lazy(()=>import('./containers/PMPCourse')) 
const CodingBootcamp = React.lazy(()=>import('./containers/CodingBootcamp')) 
const CyberSecurityCourse = React.lazy(()=>import('./containers/CyberSecurityCourse')) 
const RPACourse = React.lazy(()=>import('./containers/RPACourse'))
const PMIACP = React.lazy(()=>import('./containers/PMIACPCourse'))

export default function Routes({appProps}){

	// console.log(appProps)

	return (
		<Switch>
		

		<UnauthenticatedRoute
		  path="/"
		  exact
		  component={Home}
		  appProps={appProps} />

		<UnauthenticatedRoute
		  // path="/data/science/training/philippines"
		  path="/data/science/training/philippines"
		  exact
		  component={BigDataDataScienceMLFoundationCourse}
		  appProps={appProps} />

		 <UnauthenticatedRoute
		  path="/trainings/data_science_ccc-big-data-foundation-2"
		  exact
		  component={BigDataDataScienceMLFoundationCourse}
		  appProps={appProps} />


		  <UnauthenticatedRoute
		  path="/trainings/pmp-certification-training-philippines"
		  exact
		  component={PMPCourse}
		  appProps={appProps} />

		  
		   <UnauthenticatedRoute
		  path="/trainings/web-development-coding-philippines"
		  exact
		  component={CodingBootcamp}
		  appProps={appProps} />


		  <UnauthenticatedRoute
		  path="/trainings/rpa-robotic-process-automation-course-philippines"
		  exact
		  component={RPACourse}
		  appProps={appProps} />

		  <UnauthenticatedRoute		  
		  path="/trainings/pmi_acp-philippines"
		  exact
		  component={PMIACP}
		  // render={() => (<Redirect to="/data/science/training/singapore" />)}
		  appProps={appProps} />
		  

		  <UnauthenticatedRoute
		  path="/cyber-security-foundation-course-philippines"
		  exact
		  component={CyberSecurityCourse}
		  appProps={appProps} />

		  {/*

		<UnauthenticatedRoute 
			path="/login" 
			exact 
			component={Login} 
			appProps={appProps} />
		
		<UnauthenticatedRoute 
			path="/signup" 
			exact 
			component={Signup} 
			appProps={appProps} />

		<UnauthenticatedRoute
		  path="/login/reset"
		  exact
		  component={ResetPassword}
		  appProps={appProps} />
		*/}



		<Route component={NotFound} />
			
		</Switch>
		)
}