// React Basic and Bootstrap
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Row, Col } from 'reactstrap';
import {MDBBtn, MDBIcon, MDBBadge} from 'mdbreact'



class Pricing extends Component {

    constructor(props) {
        super(props);
        
    }
    

    componentDidMount() {
   
    }
       // Make sure to remove the DOM listener when the component is unmounted.
    componentWillUnmount() {
      
     }


    render() {

        return (
            <React.Fragment>                

                <section className="section">
                    <div className="container">
                        <Row className="justify-content-center">
                            <div className="col-12 text-center">
                                <div className="section-title mb-60">
                                    <h4 className="main-title mb-4">Pricing</h4>
                                    <p className="text-muted para-desc mb-0 mx-auto">If you have attended any of our course previously, you will get a <span className="text-primary font-weight-bold">10% discount</span> for any subsequent course you attend.</p>
                                </div>
                            </div>
                        </Row>

                        <Row className="align-items-center">
                            <Col md={4} className="col-12">
                                <div className="pricing-rates business-rate shadow bg-light pt-5 pb-5 p-4 rounded text-center">
                                    <h2 className="title text-uppercase mb-4">Project Management Professional PMP® Training <MDBBadge tag="a" color="blue">virtual</MDBBadge></h2>
                                    <div className="d-flex justify-content-center mb-4">
                                        <span className="h2 mb-0">Call/ Email us for Pricing</span>
                                    </div>

                                    <ul className="feature list-unstyled pl-0">
                                        <li className="feature-list"><i className="mdi mdi-check text-success h5 mr-1"></i>Virtual Classroom training</li>
                                        <li className="feature-list"><i className="mdi mdi-check text-success h5 mr-1"></i>E-Learning</li>
                                        <li className="feature-list"><i className="mdi mdi-close text-danger h5 mr-1"></i>Exam Fees</li>
                                        <li className="feature-list"><i className="mdi mdi-check text-success h5 mr-1"></i>Instructor support</li>
                                        <li className="feature-list"><i className="mdi mdi-check text-success h5 mr-1"></i>Group discount available</li>
                                        <li className="feature-list"><i className="mdi mdi-check text-success h5 mr-1"></i>Post class support</li>
                                    </ul>
                                    
                                    <MDBBtn color="info" onClick={()=>this.props.toggle2('enrolform')}>Enrol <MDBIcon icon={'user'} className="ml-1"/></MDBBtn>
                                </div>
                            </Col>

                            <Col md={4} className="col-12 mt-4 pt-2 mt-sm-0 pt-sm-0">
                                <div className="pricing-rates business-rate shadow bg-white pt-5 pb-5 p-4 rounded text-center">
                                    <h2 className="title text-uppercase text-primary mb-4">Project Management Professional PMP® Training <MDBBadge tag="a" color="green">Physical</MDBBadge></h2>
                                    <div className="d-flex justify-content-center mb-4">
                                        <span className="h2 mb-0">Call/ Email us for Pricing</span>
                                    </div>

                                    <ul className="feature list-unstyled pl-0">
                                        <li className="feature-list"><i className="mdi mdi-check text-success h5 mr-1"></i>Enrol 2 weeks before start</li>
                                        <li className="feature-list"><i className="mdi mdi-check text-success h5 mr-1"></i>Classroom training</li>
                                        <li className="feature-list"><i className="mdi mdi-check text-success h5 mr-1"></i>E-Learning</li>
                                        <li className="feature-list"><i className="mdi mdi-close text-danger h5 mr-1"></i>Exam Fees</li>
                                        <li className="feature-list"><i className="mdi mdi-check text-success h5 mr-1"></i>Instructor support</li>
                                        <li className="feature-list"><i className="mdi mdi-check text-success h5 mr-1"></i>Group discount available</li>
                                        <li className="feature-list"><i className="mdi mdi-check text-success h5 mr-1"></i>Post class support</li>
                                    </ul>
                                    <MDBBtn color="info" onClick={()=>this.props.toggle2('enrolform')}>Enrol <MDBIcon icon={'user'} className="ml-1"/></MDBBtn>
                                </div>
                            </Col>
                            <Col md={4} className="col-12">
                                <div className="pricing-rates business-rate shadow bg-light pt-5 pb-5 p-4 rounded text-center">
                                    <h2 className="title text-uppercase mb-4">Big Data/Data Science/ML Foundation <MDBBadge tag="a" color="blue">virtual</MDBBadge></h2>
                                    <div className="d-flex justify-content-center mb-4">
                                        <span className="h2 mb-0">Call/ Email us for Pricing</span>
                                    </div>

                                    <ul className="feature list-unstyled pl-0">
                                        <li className="feature-list"><i className="mdi mdi-check text-success h5 mr-1"></i>Virtual Classroom training</li>
                                        <li className="feature-list"><i className="mdi mdi-check text-success h5 mr-1"></i>E-Learning</li>
                                        <li className="feature-list"><i className="mdi mdi-check text-success h5 mr-1"></i>Exam Fees</li>
                                        <li className="feature-list"><i className="mdi mdi-check text-success h5 mr-1"></i>Instructor support</li>
                                        <li className="feature-list"><i className="mdi mdi-check text-success h5 mr-1"></i>Group discount available</li>
                                        <li className="feature-list"><i className="mdi mdi-check text-success h5 mr-1"></i>Post class support</li>
                                    </ul>
                                    
                                    <MDBBtn color="info" onClick={()=>this.props.toggle2('enrolform')}>Enrol <MDBIcon icon={'user'} className="ml-1"/></MDBBtn>
                                </div>
                            </Col>

                            


                        </Row>

                        <Row className="align-items-center">
                            <Col md={4} className="col-12 mt-4 pt-2 mt-sm-0 pt-sm-0">
                                <div className="pricing-rates business-rate shadow bg-white pt-5 pb-5 p-4 rounded text-center">
                                    <h2 className="title text-uppercase text-primary mb-4">Big Data/Data Science/ML Foundation <MDBBadge tag="a" color="green">Physical</MDBBadge></h2>
                                    <div className="d-flex justify-content-center mb-4">
                                        <span className="h2 mb-0">Call/ Email us for Pricing</span>
                                    </div>

                                    <ul className="feature list-unstyled pl-0">                                        
                                        <li className="feature-list"><i className="mdi mdi-check text-success h5 mr-1"></i>Classroom training</li>
                                        <li className="feature-list"><i className="mdi mdi-check text-success h5 mr-1"></i>E-Learning</li>
                                        <li className="feature-list"><i className="mdi mdi-check text-success h5 mr-1"></i>Exam Fees</li>
                                        <li className="feature-list"><i className="mdi mdi-check text-success h5 mr-1"></i>Instructor support</li>
                                        <li className="feature-list"><i className="mdi mdi-check text-success h5 mr-1"></i>Group discount available</li>
                                        <li className="feature-list"><i className="mdi mdi-check text-success h5 mr-1"></i>Post class support</li>
                                    </ul>
                                    <MDBBtn color="info" onClick={()=>this.props.toggle2('enrolform')}>Enrol <MDBIcon icon={'user'} className="ml-1"/></MDBBtn>
                                </div>
                            </Col>

                            <Col md={4} className="col-12 mt-4 pt-2 mt-sm-0 pt-sm-0">
                                <div className="pricing-rates business-rate shadow bg-white pt-5 pb-5 p-4 rounded text-center">
                                    <h2 className="title text-uppercase text-primary mb-4">Cyber Security Foundation <MDBBadge tag="a" color="blue">Virtual</MDBBadge></h2>
                                    <div className="d-flex justify-content-center mb-4">
                                        <span className="h2 mb-0">Call/ Email us for Pricing</span>
                                    </div>

                                    <ul className="feature list-unstyled pl-0">
                                        
                                        <li className="feature-list"><i className="mdi mdi-check text-success h5 mr-1"></i>Virtual Classroom training</li>                                        
                                        <li className="feature-list"><i className="mdi mdi-check text-success h5 mr-1"></i>Exam Fees</li>
                                        <li className="feature-list"><i className="mdi mdi-check text-success h5 mr-1"></i>Instructor support</li>
                                        <li className="feature-list"><i className="mdi mdi-check text-success h5 mr-1"></i>Group discount available</li>
                                        <li className="feature-list"><i className="mdi mdi-check text-success h5 mr-1"></i>Post class support</li>
                                    </ul>
                                    <MDBBtn color="info" onClick={()=>this.props.toggle2('enrolform')}>Enrol <MDBIcon icon={'user'} className="ml-1"/></MDBBtn>
                                </div>
                            </Col>

                            <Col md={4} className="col-12">
                                <div className="pricing-rates business-rate shadow bg-light pt-5 pb-5 p-4 rounded text-center">
                                    <h2 className="title text-uppercase mb-4">Cyber Security Foundation <MDBBadge tag="a" color="green">Physical</MDBBadge></h2>
                                    <div className="d-flex justify-content-center mb-4">
                                        <span className="h2 mb-0">Call/ Email us for Pricing</span>
                                    </div>

                                    <ul className="feature list-unstyled pl-0">
                                        <li className="feature-list"><i className="mdi mdi-check text-success h5 mr-1"></i>Classroom training</li>                                        
                                        <li className="feature-list"><i className="mdi mdi-check text-success h5 mr-1"></i>Exam Fees</li>
                                        <li className="feature-list"><i className="mdi mdi-check text-success h5 mr-1"></i>Instructor support</li>
                                        <li className="feature-list"><i className="mdi mdi-check text-success h5 mr-1"></i>Group discount available</li>
                                        <li className="feature-list"><i className="mdi mdi-check text-success h5 mr-1"></i>Post class support</li>
                                    </ul>
                                    
                                    <MDBBtn color="info" onClick={()=>this.props.toggle2('enrolform')}>Enrol <MDBIcon icon={'user'} className="ml-1"/></MDBBtn>
                                </div>
                            </Col>
                            
                        </Row>

                        <Row className="align-items-center">
                            <Col md={4} className="col-12 mt-4 pt-2 mt-sm-0 pt-sm-0">
                                <div className="pricing-rates business-rate shadow bg-white pt-5 pb-5 p-4 rounded text-center">
                                    <h2 className="title text-uppercase text-primary mb-4">Software/Web Development <MDBBadge tag="a" color="green">Physical</MDBBadge></h2>
                                    <div className="d-flex justify-content-center mb-4">
                                        <span className="h2 mb-0">Call/ Email us for Pricing</span>
                                    </div>

                                    <ul className="feature list-unstyled pl-0">                                        
                                        <li className="feature-list"><i className="mdi mdi-check text-success h5 mr-1"></i>Classroom training</li>
                                        <li className="feature-list"><i className="mdi mdi-check text-success h5 mr-1"></i>Serverless stack</li>
                                        <li className="feature-list"><i className="mdi mdi-check text-success h5 mr-1"></i>Exam Fees</li>
                                        <li className="feature-list"><i className="mdi mdi-check text-success h5 mr-1"></i>Instructor support</li>
                                        <li className="feature-list"><i className="mdi mdi-check text-success h5 mr-1"></i>Group discount available</li>
                                        <li className="feature-list"><i className="mdi mdi-check text-success h5 mr-1"></i>Post class support</li>
                                    </ul>
                                    <MDBBtn color="info" onClick={()=>this.props.toggle2('enrolform')}>Enrol <MDBIcon icon={'user'} className="ml-1"/></MDBBtn>
                                </div>
                            </Col>

                            <Col md={4} className="col-12 mt-4 pt-2 mt-sm-0 pt-sm-0">
                                <div className="pricing-rates business-rate shadow bg-white pt-5 pb-5 p-4 rounded text-center">
                                    <h2 className="title text-uppercase text-primary mb-4">Software/Web Development <MDBBadge tag="a" color="blue">Virtual</MDBBadge></h2>
                                    <div className="d-flex justify-content-center mb-4">
                                        <span className="h2 mb-0">Call/ Email us for Pricing</span>
                                    </div>

                                    <ul className="feature list-unstyled pl-0">
                                        
                                        <li className="feature-list"><i className="mdi mdi-check text-success h5 mr-1"></i>Virtual Classroom training</li>                                        
                                        <li className="feature-list"><i className="mdi mdi-check text-success h5 mr-1"></i>Exam Fees</li>
                                        <li className="feature-list"><i className="mdi mdi-check text-success h5 mr-1"></i>Instructor support</li>
                                        <li className="feature-list"><i className="mdi mdi-check text-success h5 mr-1"></i>Group discount available</li>
                                        <li className="feature-list"><i className="mdi mdi-check text-success h5 mr-1"></i>Post class support</li>
                                    </ul>
                                    <MDBBtn color="info" onClick={()=>this.props.toggle2('enrolform')}>Enrol <MDBIcon icon={'user'} className="ml-1"/></MDBBtn>
                                </div>
                            </Col>  

                            <Col md={4} className="col-12 mt-4 pt-2 mt-sm-0 pt-sm-0">
                                <div className="pricing-rates business-rate shadow bg-white pt-5 pb-5 p-4 rounded text-center">
                                    <h2 className="title text-uppercase text-primary mb-4">Robotic Process Automation (RPA) <MDBBadge tag="a" color="green">Physical</MDBBadge></h2>
                                    <div className="d-flex justify-content-center mb-4">
                                        <span className="h2 mb-0">Call/ Email us for Pricing</span>
                                    </div>

                                    <ul className="feature list-unstyled pl-0">                                        
                                        <li className="feature-list"><i className="mdi mdi-check text-success h5 mr-1"></i>Classroom training</li>
                                        <li className="feature-list"><i className="mdi mdi-check text-success h5 mr-1"></i>Build simple bots</li>
                                        <li className="feature-list"><i className="mdi mdi-check text-success h5 mr-1"></i>Exam Fees</li>
                                        <li className="feature-list"><i className="mdi mdi-check text-success h5 mr-1"></i>Instructor support</li>
                                        <li className="feature-list"><i className="mdi mdi-check text-success h5 mr-1"></i>Group discount available</li>
                                        <li className="feature-list"><i className="mdi mdi-check text-success h5 mr-1"></i>Post class support</li>
                                    </ul>
                                    <MDBBtn color="info" onClick={()=>this.props.toggle2('enrolform')}>Enrol <MDBIcon icon={'user'} className="ml-1"/></MDBBtn>
                                </div>
                            </Col>   

                            <Col md={4} className="col-12 mt-4 pt-2 mt-sm-0 pt-sm-0">
                                <div className="pricing-rates business-rate shadow bg-white pt-5 pb-5 p-4 rounded text-center">
                                    <h2 className="title text-uppercase text-primary mb-4">Robotic Process Automation (RPA) <MDBBadge tag="a" color="blue">Virtual</MDBBadge></h2>
                                    <div className="d-flex justify-content-center mb-4">
                                        <span className="h2 mb-0">Call/ Email us for Pricing</span>
                                    </div>

                                    <ul className="feature list-unstyled pl-0">                                        
                                        <li className="feature-list"><i className="mdi mdi-check text-success h5 mr-1"></i>Classroom training</li>
                                        <li className="feature-list"><i className="mdi mdi-check text-success h5 mr-1"></i>Build simple bots</li>
                                        <li className="feature-list"><i className="mdi mdi-check text-success h5 mr-1"></i>Exam Fees</li>
                                        <li className="feature-list"><i className="mdi mdi-check text-success h5 mr-1"></i>Instructor support</li>
                                        <li className="feature-list"><i className="mdi mdi-check text-success h5 mr-1"></i>Group discount available</li>
                                        <li className="feature-list"><i className="mdi mdi-check text-success h5 mr-1"></i>Post class support</li>
                                    </ul>
                                    <MDBBtn color="info" onClick={()=>this.props.toggle2('enrolform')}>Enrol <MDBIcon icon={'user'} className="ml-1"/></MDBBtn>
                                </div>
                            </Col>       

                            <Col md={4} className="col-12">
                                <div className="pricing-rates business-rate shadow bg-light pt-5 pb-5 p-4 rounded text-center">
                                    <h2 className="title text-uppercase mb-4">PMI ACP Course <MDBBadge tag="a" color="blue">virtual</MDBBadge></h2>
                                    <div className="d-flex justify-content-center mb-4">
                                        <span className="h2 mb-0">Call/ Email us for Pricing</span>
                                    </div>

                                    <ul className="feature list-unstyled pl-0">
                                        <li className="feature-list"><i className="mdi mdi-check text-success h5 mr-1"></i>Virtual Classroom training</li>
                                        <li className="feature-list"><i className="mdi mdi-check text-success h5 mr-1"></i>E-Learning</li>
                                        <li className="feature-list"><i className="mdi mdi-close text-danger h5 mr-1"></i>Exam Fees</li>
                                        <li className="feature-list"><i className="mdi mdi-check text-success h5 mr-1"></i>Instructor support</li>
                                        <li className="feature-list"><i className="mdi mdi-check text-success h5 mr-1"></i>Group discount available</li>
                                        <li className="feature-list"><i className="mdi mdi-check text-success h5 mr-1"></i>Post class support</li>
                                    </ul>
                                    
                                    <MDBBtn color="info" onClick={()=>this.props.toggle2('enrolform')}>Enrol <MDBIcon icon={'user'} className="ml-1"/></MDBBtn>
                                </div>
                            </Col>

                            <Col md={4} className="col-12 mt-4 pt-2 mt-sm-0 pt-sm-0">
                                <div className="pricing-rates business-rate shadow bg-white pt-5 pb-5 p-4 rounded text-center">
                                    <h2 className="title text-uppercase text-primary mb-4">PMI ACP Course<MDBBadge tag="a" color="green">Physical</MDBBadge></h2>
                                    <div className="d-flex justify-content-center mb-4">
                                        <span className="h2 mb-0">Call/ Email us for Pricing</span>
                                    </div>

                                    <ul className="feature list-unstyled pl-0">                                        
                                        <li className="feature-list"><i className="mdi mdi-check text-success h5 mr-1"></i>Classroom training</li>
                                        <li className="feature-list"><i className="mdi mdi-check text-success h5 mr-1"></i>E-Learning</li>
                                        {/* <li className="feature-list"><i className="mdi mdi-check text-success h5 mr-1"></i>CITREP Funding</li> */}
                                        {/* <li className="feature-list"><i className="mdi mdi-check text-success h5 mr-1"></i>SkillsFuture Credit <span style={{"font-size":"9px"}}>(Maximum S$ 500)</span></li> */}
                                        <li className="feature-list"><i className="mdi mdi-close text-danger h5 mr-1"></i>Exam Fees</li>
                                        <li className="feature-list"><i className="mdi mdi-check text-success h5 mr-1"></i>Instructor support</li>
                                        <li className="feature-list"><i className="mdi mdi-check text-success h5 mr-1"></i>Group discount available</li>
                                        <li className="feature-list"><i className="mdi mdi-check text-success h5 mr-1"></i>Post class support</li>
                                    </ul>
                                    <MDBBtn color="info" onClick={()=>this.props.toggle2('enrolform')}>Enrol <MDBIcon icon={'user'} className="ml-1"/></MDBBtn>
                                </div>
                            </Col>
                            
                        </Row>


                    </div>
                    
                </section>
                
            </React.Fragment>
        );
    }
}
export default Pricing;
